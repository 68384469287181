import React from "react";
import LokalitaTemplate from "../../templates/LokalitaTemplate";
import { StaticQuery, graphql, Link } from "gatsby"
import RegionyTemplate from "../../templates/RegionyTemplate";
import UniversalTemplate from "../../templates/UniversalTemplate";
import CenovaKalkulacka from "../../components/CenovaKalkulacka";


const faqData = [
  {
    otazka: "Co všechno obsahuje cena?",
    odpoved: `Ta obsahuje vyzvednutí zesnulého kdekoliv po Praze a blízkého okolí, převezení do krematoria, samotný žeh a následné uložení do dočasné urny. Doručení urny do vlastních rukou kamkoliv po Praze. V ceně je také administrativa spojená s vyřízením <a href="/slovnik-pojmu/umrtni-list/">úmrtního listu</a> a možnost obracet se na naši zákaznickou linku 24 hodin 7 dní v týdnu.`
  },
  {
    otazka: "Budu platit něco navíc?",
    odpoved: `Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí zesnulého došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom <a href="/slovnik-pojmu/vyzvednuti-zesnuleho/">nemocnice</a> nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás. Podobný poplatek může vystavit krematorium pokud měl pozůstalý více než 100kg a musela by se použít nadměrná rakev.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo dané město. To se pak počítá podle <a href="/slovnik-pojmu/cena-za-dopravu/">najetých kilometrů</a>.`
  },
  {
    otazka: "Kdy a jak se platí?",
    odpoved: "Platba zálohy probíhá vždy předem. Je možné ji provést buď bankovním převodem nebo online přes platební bránu. Fakturu s údaji o platbě obdržíte po vytvoření závazné objednávky. Po pohřbu zákazník obdrží druhou fakturu za zbytek částky která není obsažená v záloze. Například v případě, že využijete i některých dalších služeb které nabízíme: jiná urna, květiny a podobně. Moc rádi vám v případě zájmu zařídíme i platbu na splátky skrz naše partnery. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Je možné platit na splátky?",
    odpoved: "Ano, u nás můžete platit na splátky. Prostřednictvím našich partnerů jsme vám schopni splácení faktury zařídit do 24 hodin. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Jaké dokumenty budu potřebovat?",
    odpoved: `Pokud je zesnulý doma, budete k předání pohřební službě potřebovat <a href="/slovnik-pojmu/list-o-prohlidce-tela/">ohledací list</a>, který vystaví Zdravotnická záchranná služba nebo <a href="/slovnik-pojmu/koroner/">koroner</a>. Pokud je v nemocnici nebo pečovatelském domě, postarají se o vystavení dokumentu i předání pohřební službě příslušní zaměstnanci. Dále od vás budeme potřebovat <span>kopii vašeho občanského průkazu</span>, občanský průkaz zesnulé/ho a <span>plnou moc</span>, kterou s vámi podepíšeme online. Ta se musí vystavit proto, abychom mohli manipulovat s tělem zesnulého.`
  },
  {
    otazka: "Poskytujete služby po celé ČR?",
    odpoved: `V současné chvíli poskytujeme pohřební služby téměř po celé ČR. Konkrétní město si můžete zkontrolovat v naší sekci <a href="/pobocky/">pobočky</a>. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.`
  },
  {
    otazka: "Jak si můžu být jistý/á, že nedojde k záměně popela?",
    odpoved: `Krematorium je ze zákona o pohřebnictví povinno do <a href="/rakve/">rakve</a> vkládat kovový plíšek s označením. Ten není během kremace poškozen a následně je i s popelem vložen do stejně označené <a href="/poradna/kremacni-popel/">urny</a>. Krematorium vede o celém procesu podrobnou evidenci, takže záměna je vyloučena.`
  },
];


export const query = graphql`
  query {
    praha9: file(relativePath: { eq: "praha-9.jpg" }) {
      childImageSharp {
        fixed(width: 350, height: 350) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

const Praha9 = ({data}) => {


  return(
    <UniversalTemplate 
      h1="Pohřební služba Praha 9 - Čakovice, Vysočany"
      title={"Pohřební služba Praha 9 (Čakovice, Vysočany) - Empatická 24/7"}
      desc="Jsme tu pro vás, pokud hledáte pohřební službu na Praze 9 v blízkosti Nemocnice ve Vysočanech nebo kdekoli jinde v Čakovicích nebo Proseku."
      perex={<>Pokud váš blízký zemřel v Nemocnici ve Vysočanech, v Domově seniorů Novovysočanská, v Hloubětíně nebo u vás doma kdekoli jinde po Praze 9, můžete se obrátit na naši pohřební službu. Jsme vám k dispozici na telefonu 24 hodin 7 dní v týdnu.</>}
      imgFixed={data.praha9.childImageSharp.fixed}
      imgAlt="Poliklinika Vysočany na Praze 9"
      breadscrumStranky={[
        {name: "Domů", url: "/"},
        {name: "Pohřební služba Praha", url: "/pohrebni-sluzba-praha/"},
        {name: "Pohřební služba Praha 9 (Čakovice, Vysočany)", url: "/pohrebni-sluzba-praha/praha-9/"}
      ]}
      faqData={faqData}
      mesto="Praha"
      seVsimText={"Chápeme, že smrt blízkého je situace, kterou neplánujete. Zavolejte nám a my jsme připraveni vaši situaci okamžitě řešit. Se vším vám pomůžeme a vytvoříme pro vás a vaše blízké to nejosobnější poslední rozloučení na Praze 9 a okolí."}
      
      content={<>
        <p>Jsme spolehlivá <Link to="/pohrebni-sluzba-praha/">pohřební služba v Praze</Link>, která se postará o důstojné poslední rozloučení s vaším blízkým. Pomůžeme vám vše vyřídit přes internet z bezpečí vašeho domova. Můžete se na nás obrátit ať máte zájem o kremaci bez obřadu nebo rozloučení v jedné z pražských smutečních síní. Obřad je možné zajistit například v <Link to="/obradni-sine/obradni-sin-praha-olsany/">Ústřední obřadní síni</Link>, v <Link to="/obradni-sine/obradni-sin-motol/">Obřadní síni krematoria Motol</Link> na Praze 5 nebo v jakékoli jiné síni dle vašeho přání.</p>
        <p>Pokud váš blízký zemřel v Nemocnici ve Vysočanech, kontaktujte nás a my už se v nemocnici domluvíme na jeho vyzvednutí. V případě <Link to="/poradna/umrti-blizke-osoby-pruvodce/">úmrtí doma</Link> se můžete obrátit na naši nonstop linku a do několika málo hodin k vám přijedeme, ať bydlíte na Praze 9 ve Vysočanech, Proseku nebo Hrdlořezech.</p>

        <CenovaKalkulacka compact />

        <h2>Jaký je postup?</h2>
        
        <h3>Nezávazně si zavoláme a probereme vaši situaci</h3>
        <p>Chápeme, že smrt blízkého je událost, kterou neplánujete. Pokud nám zavoláte, jsme připraveni vaši situaci okamžitě řešit. Probereme spolu vaši představu a pomůžeme vám najít ideální řešení pro vás a vaši rodinu, ať už se bude jednat o kremaci bez obřadu nebo rozloučení v některé z pražských obřadních síní. Na telefonu jsme pro vás každý den, ve dne i v noci.</p>
        
        <h3>Pohřeb s námi naplánujete z bezpečí domova</h3>
        <p>Na plánování budete mít dostatek času a nemusíte nikam chodit. Vše s vámi vyřešíme po telefonu a e-mailu. Bez zbytečného tlaku a stresu si tak můžete vybrat všechny detaily obřadu od <Link to="/poradna/smutecni-kytice-pohreb/">květinové výzdoby</Link> přes hudbu až po ozdobný obal na urnu nebo <Link to="/slovnik-pojmu/pamatecni-predmety/">památeční předmět</Link> vytvořený z popela. My jsme vám celou dobu k dispozici a připraveni pomoci.</p>
        
        <h3>O vše ostatní se už postaráme my</h3>
        <p>Ať už si vyberete jakýkoliv pohřeb, naší prioritou je, abyste se mohli s vaším blízkým rozloučit důstojně a podle vašich představ. My jsme tu, abychom vyřešili formality na matrice, oblečení a úpravu zesnulého, přípravu smuteční síně a další náležitosti. V rámci služby Péče+ vám také osobně dodáme vytištěné parte, doručíme urnu kamkoliv po Praze 9. Můžeme se postarat také o převod digitálních účtů vašeho blízkého.</p>

        <h2>V čem jsme jiní než ostatní pohřební služby na Praze 9?</h2>

        <h3>Vše vyřešíme přes internet</h3>
        <p>Ať váš blízký zemřel doma, v Nemocnici ve Vysočanech, v Domově seniorů Novovysočanská nebo kdekoli jinde na Praze 9, nemusíte nikam chodit. Vše vyřídíte z bezpečí vašeho domova, kde si můžete detaily posledního rozloučení v klidu rozmyslet a probrat je s rodinou.</p>
        
        <h3>Vždy za transparentní ceny</h3>
        <p>Cena, na které se domluvíme, je vždy konečná. Obsahuje kompletně vše, co budete potřebovat, včetně paušálních kilometrů po celé Praze.</p>
        
        <h3>Poskytneme vám speciální péči</h3>
        <p>Vytiskneme pro vás parte a zavezeme vám jej kamkoliv po celé Praze 9. Vyřídíme komunikaci s matrikou pro vystavení <Link to="/slovnik-pojmu/umrtni-list/">úmrtního listu</Link>. Osobně vám doručíme urnu domů nebo na místo podle vašeho přání.</p>
        
        <p>Jako další vás mohou zajímat lokality:</p>
        <ul>
          <li><Link to="/pohrebni-sluzba-praha/praha-2/">Pohřební služba Praha 2 - Nové Město</Link></li>
          <li><Link to="/pohrebni-sluzba-praha/praha-3/">Pohřební služba Praha 3 - Vinohrady</Link></li>
          <li><Link to="/pohrebni-sluzba-praha/praha-4/">Pohřební služba Praha 4 - Krč, Modřany</Link></li>
          <li><Link to="/pohrebni-sluzba-praha/praha-5/">Pohřební služba Praha 5 - Motol, Smíchov</Link></li>
          <li><Link to="/pohrebni-sluzba-praha/praha-6/">Pohřební služba Praha 6 - Břevnov, Střešovice</Link></li>
          <li><Link to="/pohrebni-sluzba-praha/praha-8/">Pohřební služba Praha 8 - Bulovka</Link></li>
          <li><Link to="/pohrebni-sluzba-praha/praha-10/">Pohřební služba Praha 10 - Strašnice</Link></li>
        </ul>
      </>}
    />
  );
}


export default Praha9;
