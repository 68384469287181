import { Link } from 'gatsby';
import React from 'react';
import styled from "styled-components";
import KalkulackaImg from "../assets/img/kalkulacka.svg";
import GooglePic from "../assets/img/googleFullRating.svg";
import FirmyPic from "../assets/img/firmyFullRating.svg";

const CenovaKalkulackaInner = styled.div`
  background: #F3F1EB;
  padding: 30px;
  border-radius: 10px;
  margin: 60px auto;
  ${props => props.compact && "margin: 0 auto;"};
  ${props => props.compact && "margin-top: 60px;"};
  display: flex;
  /* ${props => props.compact && "display: block;"}; */
  ${props => props.compact && "padding: 20px;"}
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 580px) {
    display: block;
  }

  @media (max-width: 400px) {
    padding: 20px;
    margin: 40px auto;
  }

  img {
    width: 80px;
    opacity: 0.2;
    order: 2;
    ${props => props.compact && "width: 50px;"};
    /* ${props => props.compact && "margin-bottom: 20px"}; */

    @media (max-width: 580px) {
      display: block;
      width: 50px;
      margin-bottom: 20px;
      ${props => props.compact && "width: 35px;"};
    }
  }

  div {
    width: calc(100% - 120px);
    order: 1;
    ${props => props.compact && "width: calc(100% - 60px);"};


    @media (max-width: 580px) {
      width: 100%;
    }
  }

  h2 {
    font-family: Visuelt-Medium,Helvetica !important;
    font-size: 23px !important;
    color: #0c1c31;
    margin: 0 !important; 
    
    ${props => props.compact && "font-size: 19px !important;"};
  }

  p {
    margin: 15px auto !important;
  }

  a {
    font-family: Visuelt-Medium;
    font-size: 18px;
    color: #000;
    text-align: center;
    max-width: 290px;
    display: block;
    padding: 20px 0;
    border-radius: 5px;
    background: #e5ddc4;
    text-decoration: none;
    margin-top: 30px;

    &:hover {
      background: #d9ceae;
    }
  }
`;

const Rating = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 60px;
  width: 100%;

  @media (max-width: 540px) {
    display: block;
  }

  img {
    display: block;
    max-height: 28px;
    max-width: 200px;
    margin-right: 30px;
    width: fit-content;

    @media (max-width: 899px) { 
      width: fit-content;
      margin-right: 20px;
    }

    @media (max-width: 540px) {
      margin-top: 10px;

      max-height: none;
      max-width: none;
    }
  }
`;

const CenovaKalkulacka = ({compact}) => {

  return (
    <>
      <CenovaKalkulackaInner compact={compact}>
        <img src={KalkulackaImg} alt="Kalkulačka ikona" />
        <div>
          <h2>Cenová kalkulačka: Zjistěte přesnou cenu pohřbu do 5 minut</h2>
          <p>Nezávazně odpovězte na 7 otázek a okamžitě získejte cenovou nabídku na pohřeb podle vašich představ. Všechny ceny zobrazujeme transparentně a jsou konečné.</p>
          <Link to="/nabidka/dotaznik/">Nezávazně zjistit cenu</Link>
        </div>
      </CenovaKalkulackaInner>
      {compact &&
        <Rating>
          <img alt="Nejlépe hodnocená pohřební služba - Google" src={GooglePic} />
          <img alt="Nejlépe hodnocená pohřební služba - Firmy.cz" src={FirmyPic} />
        </Rating>
      }
    </>
  );
}

export default CenovaKalkulacka;
