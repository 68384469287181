import React, {useState, useEffect} from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import styled from "styled-components";
import Img from "gatsby-image";
import Breadscrum from "../components/Breadscrum";
import CenovaKalkulacka from "../components/CenovaKalkulacka";

import RecenzePohreb from "../components/pohrby/RecenzePohreb";
import SeVsimPomuzeme from "../components/pohrby/SeVsimPomuzeme";
import FAQ from "../components/FrequentlyAskedQ";
import Phone from "../assets/img/telefon.svg";
import NaseSluzbyV2 from "../components/brandingV2/NaseSluzbyV2";



const UniversalTemplateInner = styled.section`
  background: #FBFBFA;

  .top {
    padding-top: 80px;
    padding-bottom: 90px;
    margin: 0 auto;
    max-width: 1150px;
    width: calc(100% - 100px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 899px) {
      width: calc(100% - 50px);
      padding-top: 40px;
      padding-bottom: 40px;
      display: block;
    }

    .left {
      max-width: 700px;
      width: calc(100% - 400px);

      @media (max-width: 899px) {
        width: calc(100%);
        max-width: 600px;
        margin: 0 auto;
      }

      h1 {
        font-family: NewKansas;
        font-size: 37px;
        color: #243A58;
        margin-top: 20px;

        @media (max-width: 899px) {
          font-size: 32px;
        }

        @media (max-width: 600px) {
          font-size: 28px;
        }
      }

      .perex {
        font-family: Visuelt-Regular;
        font-size: 18px;
        color: #2F2F2F;
        line-height: 27px;
        margin-top: 20px;
        margin-bottom: 40px;
        border-bottom: 1px solid #d4d4d4;
        padding-bottom: 40px;

        @media (max-width: 899px) {
          font-size: 16px;
          line-height: 25px;
        }
      }

      .content {
        h2 {
          margin-bottom: 20px;
          margin-top: 40px;
          font-family: Visuelt-Medium;
          color: #000000;
        }

        h3 {
          margin-bottom: 15px;
          margin-top: 30px;
          font-family: Visuelt-Medium;
          color: #000000;
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #2F2F2F;
          line-height: 25px;
          margin-bottom: 20px;

          a {
            color: #2F2F2F;
          }
        }

        ul {
          padding-left: 20px;

          li {
            margin-bottom: 5px;
            
            a {
              color: #2F2F2F;
            }
          }
        }
      }
    }

    .right {
      @media (max-width: 899px) {
        width: calc(100%);
        max-width: 600px;
        margin: 0 auto;
        margin-top: 40px;
      }

      .obrazekMain {
        border-radius: 10px;
        display: block;
        max-width: 100%;

        @media (max-width: 500px) {
          height: 200px !important;
        }
      }

      .altPopis {
        display: block;
        margin-top: 15px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #494949;
        line-height: 25px;
      }

      .specialistka {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        width: fit-content;

        /* @media (max-width: 899px) {
          justify-content: left;
          margin-top: 40px;
        } */

        .leva {
          position: relative;
          margin-right: 30px;

          /* @media (max-width: 899px) {
            margin-right: 23px;
          } */

          .greenLight {
            width: 25px;
            height: 25px;
            animation: pulse 2s infinite;
            background: #49C060;
            border-radius: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
          }

          @keyframes pulse {
            0% {
              opacity: 1;
            }

            50% {
              opacity: 0.25;
            }

            100% {
              opacity: 1;
            }
          }

          img {
            height: 100px;
            border-radius: 100%;

            /* @media (max-width: 899px) {
              width: 80px !important;
              height: 80px !important;
            } */
          }
        }

        .personText {

          h3 {
            font-family: Visuelt-Medium;
            font-size: 16px;
            color: #000000;
          }

          p {
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #737373;
            margin-top: 7px;
            margin-bottom: 15px;
            
            /* @media (max-width: 899px) {
              margin-bottom: 12px;
            } */
          }

          a {
            text-decoration: none;
            display: flex;
            align-items: center;

            img {
              margin-right: 12px;
              
              /* @media (max-width: 899px) {
                width: 17px;
                margin-right: 10px;
              }        */
            }

            span {
              font-family: Visuelt-Bold;
              font-size: 21.6px;
              color: #000000;
              margin-bottom: -3px;

              @media (max-width: 899px) {
                font-size: 17px;
              }
            }
          }
        }
      }
    }
  }

  .naseSluzby {
    background: #F4F3F0;
    width: 100%;
    padding: 60px 0;
    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: 600px) {
      padding: 40px 0;
    }
  }
`;

const UniversalTemplate = ({
  h1,
  imgFixed,
  imgAlt,
  breadscrumStranky,
  title,
  desc,
  perex,
  content,
  faqData,
  mesto,
  seVsimText,
}) => {



  let structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": h1,
    "brand":"Goodbye.cz",
    "image":"https://goodbye.cz/rakev.jpg",
    "description": desc,
    "aggregateRating": {
      "@type":"AggregateRating",
      "bestRating": "5",
      "ratingCount": 23,
      "ratingValue": 4.9,
      "worstRating": "1"
    }
  };


  return (
    <Layout V2>
      
      <SEO
        title={title}
        description={desc}
        customTitle={true}
      />

      <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}} />

      <UniversalTemplateInner>
        <section className="top">
          <div className="left">
            <Breadscrum
              visible={true}
              stranky={breadscrumStranky}
            />

            <h1>{h1}</h1>
            <p className="perex">{perex}</p>


            <div className="content">
              {content}
            </div>

            
          </div>
          <div className="right">
            <Img className="obrazekMain" alt={imgAlt} fixed={imgFixed} />
            <span className="altPopis">{imgAlt}</span>

            <div className="specialistka">
              <div className="leva">
                <div className="greenLight"></div>
                <StaticQuery
                  query={graphql`
                    query {
                      anicka: file(relativePath: { eq: "zamestnanecSample.jpg" }) {
                        childImageSharp {
                          fixed(width: 100, height: 100) {
                            ...GatsbyImageSharpFixed_noBase64
                          }
                        }
                      }
                    }
                  `}
                  render={data => (
                    <Img className="obrazek" alt="Naše podpora" fixed={data.anicka.childImageSharp.fixed} />
                  )}
                />
              </div>
              <div className="personText">
                <h3>Anna Janoštíková</h3>
                <p>Specialistka na telefonu</p>
                <a href="tel:+420315558136">
                  <img src={Phone} alt="" />
                  <span>315 558 136</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        
        <NaseSluzbyV2 />

        <RecenzePohreb customMesto={mesto} />

        <SeVsimPomuzeme customText={seVsimText} />

        <FAQ data={faqData} />
      </UniversalTemplateInner>
       
    </Layout>
  );
}

export default UniversalTemplate;
